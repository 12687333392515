import React from 'react';

import { Layout } from 'antd';
import { CopyrightOutlined } from '@ant-design/icons';

import styles from './footer.module.less';

const { Footer } = Layout;

type Props = {
  site: any;
};

const FooterComponent = ({ site }: Props) => (
  <>
    <Footer className={styles.footer}>
      <CopyrightOutlined /> {new Date().getFullYear()} {site.legalName}. All
      rights reserved.
    </Footer>
  </>
);
export default FooterComponent;
