import React, { ReactNode, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Layout as Body } from 'antd';

import Header from './header';
import Footer from './footer';
import FooterLinks from './footer-links';

import styles from './layout.module.less';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';

type Props = {
  children: ReactNode;
  id?: string;
};

const { Content } = Body;

const Layout = ({ children }: Props) => {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          legalName
        }
      }
    }
  `);
  const store = useSelector((state: any) => state);

  useEffect(() => console.log('store', store), [store]);

  return (
    <Body>
      <Header site={site} />
      <Content className={styles.body}>
        {children}
        <FooterLinks />
      </Content>
      <Footer site={site} />
    </Body>
  );
};

export default Layout;
