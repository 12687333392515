import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './page.module.less';
import Layout from './layout';

type Props = {
  children: ReactNode;
  id?: string;
  className?: string;
  color?: string;
  padding?: string;
};

const Wrapper = ({ children, id, className }: Props) => (
  <div id={id} className={classNames(styles.wrapper, className)}>
    {children}
  </div>
);

const Section = ({
  children,
  id,
  className,
  color,
  padding = '32px 0',
}: Props) => (
  <section
    id={id}
    className={classNames(styles.section, color && styles[color], className)}
    style={{
      padding,
    }}
  >
    <Wrapper>{children}</Wrapper>
  </section>
);

const Page = ({ children, id, className, color }: Props) => (
  <Layout>
    <div
      id={id}
      className={classNames(
        styles.typography,
        styles.page,
        color && styles[color],
        className
      )}
    >
      {children}
    </div>
  </Layout>
);

export { Page, Section };
