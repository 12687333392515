import React from 'react';
import styles from './logo.module.less';
import { Link } from 'gatsby';

type Props = {
  src: string;
  alt: string;
  link: string;
};

const Logo = ({ src, alt, link }: Props) => (
  <Link to={link} className={styles.logo}>
    <img className={styles.image} src={src} alt={alt} />
  </Link>
);

export default Logo;
