import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { Section } from './page';

import styles from './footer-links.module.less';

const FooterLinks = () => (
  <Section color="black" padding={`16px 0`} className={styles.footerLinks}>
    <Row gutter={32} justify="start">
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <ul className={styles.right}>
          <li>
            <a href="https://www.facebook.com/livemenu.io">
              <FontAwesomeIcon icon={faFacebook} size="2x" />
            </a>
          </li>
          <li>
            <a href="https://instagram.com/livemenu.io">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </li>
          <li>
            <a href="https://twitter.com/livemenu_io">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
          </li>
        </ul>
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <ul className={styles.right}>
          <li>
            <Link to="/terms/">Terms of Use</Link>
          </li>
          <li>
            <Link to="/privacy/">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/copyright/">Copyright Policy</Link>
          </li>
        </ul>
      </Col>
    </Row>
  </Section>
);

export default FooterLinks;
