import React from 'react';
import { useAuth } from '@providers/auth-provider';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/pro-light-svg-icons';
import styles from './login.module.less';

const LoginButton = () => {
  const { login } = useAuth();

  return (
    <span className={styles.authBtns}>
      <Button
        onClick={login}
        type="ghost"
        className={styles.loginBtn}
        icon={
          <FontAwesomeIcon size="1x" className="menu-icon" icon={faSignInAlt} />
        }
      >
        Sign In
      </Button>
    </span>
  );
};

export default LoginButton;
