import React from 'react';
import { Layout, Menu, Affix, Button, Avatar } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faHatChef } from '@fortawesome/pro-light-svg-icons';
import { faUser, faHeart } from '@fortawesome/pro-regular-svg-icons';
import { window } from 'browser-monads';
import classNames from 'classnames';

import LoginButton from '@components/auth/login-button';
import LogoutLink from '@components/auth/logout-link';
import Logo from './logo';
import { useAuth } from '@providers/auth-provider';

import styles from './header.module.less';

type Props = {
  site: any;
};

const { Header } = Layout;
const { location } = window;

const HeaderComponent = ({ site }: Props) => {
  const selected = location.pathname;
  const { isAuthenticated, user } = useAuth();

  return (
    <Affix>
      <Header className={styles.header}>
        <nav className={classNames(styles.nav, styles.buttons)}>
          <Logo src={'/reactable-logo-alt.svg'} alt={site.name} link="/" />
          <Menu
            className={styles.menu}
            theme="dark"
            mode="horizontal"
            overflowedIndicator={<MenuOutlined />}
            selectedKeys={[selected]}
          >
            {/* <Menu.Item key="/people">
              <Link
                to="/people/"
                partiallyActive={true}
                activeStyle={{ color: '#ff005e' }}
              >
                <FontAwesomeIcon
                  size="1x"
                  className="menu-icon"
                  icon={faHatChef}
                />{' '}
                People
              </Link>
            </Menu.Item> */}
            <Menu.Item key="/places">
              <Link
                to="/places/"
                partiallyActive={true}
                activeStyle={{ color: '#ff005e' }}
              >
                <FontAwesomeIcon
                  size="1x"
                  className="menu-icon"
                  icon={faUtensils}
                />{' '}
                Places
              </Link>
            </Menu.Item>
            {!isAuthenticated && (
              <Menu.Item className={styles.login} key="/login">
                <LoginButton />
              </Menu.Item>
            )}
            {isAuthenticated && (
              <Menu.SubMenu
                title="Profile"
                key="profile"
                className={styles.profileMenu}
                popupClassName={styles.profileSubmenu}
                icon={
                  (user?.picture && (
                    <Avatar
                      size="small"
                      className={styles.avatar}
                      src={user?.picture}
                      alt={`Profile for ${user.name}`}
                    />
                  )) || (
                    <FontAwesomeIcon
                      size="1x"
                      className="menu-icon"
                      icon={faUser}
                    />
                  )
                }
              >
                {/* <Menu.Item key="/favorites">
                  <Button
                    type="link"
                    className={styles.linkBtn}
                    icon={
                      <FontAwesomeIcon
                        size="1x"
                        className="menu-icon"
                        icon={faHeart}
                      />
                    }
                  >
                    Favorites
                  </Button>
                </Menu.Item> */}
                <Menu.Item key="/logout">
                  <LogoutLink />
                </Menu.Item>
              </Menu.SubMenu>
            )}
            {/* <span className={styles.tagline}>React. Reward. Share.</span> */}
          </Menu>
        </nav>
      </Header>
    </Affix>
  );
};

export default HeaderComponent;
