import React from 'react';
import { useAuth } from '@providers/auth-provider';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons';
import styles from './login.module.less';

const LogoutLink = () => {
  const { isAuthenticated, logout } = useAuth();

  return (
    <span className={styles.authBtns}>
      {isAuthenticated && (
        <Button
          className={styles.logoutBtn}
          onClick={logout}
          type="link"
          icon={
            <FontAwesomeIcon
              size="1x"
              className="menu-icon"
              icon={faSignOutAlt}
            />
          }
        >
          Sign Out
        </Button>
      )}
    </span>
  );
};

export default LogoutLink;
